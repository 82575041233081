import React, { useEffect, useRef, useState } from 'react';

import './App.css';

function App() {

  const introLogoSrc = 'https://fst.fabtale.com/tz/web/telezing-logo-white.svg';
  const videoSrc = "https://fst.fabtale.com/assets/TeleZing_Intro_Overview_1080.mp4"
  const logoRef = useRef( new Image() );

  const [introLogoStyle, setIntroLogoStyle] = useState({opacity: 0, transform: "scale(0.9)" } );
  const [ videoStyle, setVideoStyle] = useState( { opacity: 0 } )
  const videoRef = useRef<HTMLVideoElement>(null);

  const videoStatusRef = useRef( "playing" );

  useEffect( () => {

    if ( ! logoRef.current.onload )
    {
      logoRef.current.onload = () => {
      setIntroLogoStyle( { opacity: 1, transform: "scale(1)"  } );
      }
      logoRef.current.src = introLogoSrc;

      setTimeout(() => {
        showVideo();
      }, 5000);
    }
  },[] )


  const showVideo = async () => {


    videoRef.current?.addEventListener( "click", (event) => {
      // videoRef.current?.setAttribute( "controls", "true" );
      console.log( videoStatusRef.current )
      if ( videoStatusRef.current == "paused" )
      {
        try {
          videoRef.current?.play()
        } catch(e ) {
          console.log( e )
        }
      }
      else
      {
        videoRef.current?.pause()
      }

    })

    videoRef.current?.addEventListener("playing", function() {
      console.log( "playing")
        videoStatusRef.current = "playing";
  });

  videoRef.current?.addEventListener( "ended", (event) => {
    // videoRef.current?.setAttribute( "controls", "true" );
    //videoStatusRef.current = "ended";
  })

  // Pause event
  videoRef.current?.addEventListener("pause", function() {
    console.log( "paused")
    videoStatusRef.current = "paused";
  });

    if ( videoRef.current )
    {
      videoRef.current.currentTime = 0;
    }
    setTimeout(() => {
      setVideoStyle( { opacity: 1 } )


    if ( videoRef.current )
    {
      try {

        videoRef.current.play()
      } catch(e ) {
        console.log( e )
      }

    }
    }, 200);

  }

  return (
    <div className="App">
      <div className='background_image'></div>
      <div className='top_panel'>
      <div className='top_panel_background'></div>
        <div className='intro_logo_holder'>
          <div className='intro_logo' style={introLogoStyle}></div>
        </div>
        <div className='video_holder' style={videoStyle}>
          <video className='video' src={videoSrc} muted autoPlay playsInline ref={videoRef}/>
        </div>
      </div>
      <div className='bottom_panel'>

        <div className='panel_fade'></div>
        <div className='info'>
          <div className='info_top'>
            <div className='info_logo'></div>
            <div className='info_text'>
              <p>A fully customizable, multiplatform, collaborative tool to boost productivity and engagement.</p>
              <p>TeleZing’s patented 4-quadrants approach is a much more simplified communication medium that cuts across all learning styles.</p>
          </div>
        </div>
        <div className='info_bottom'>
          <div>TeleZing is currently available by invitation only - email <a href="mailto:support@telezing.com?subject=TeleZing Access Request" target="_self">support@telezing.com</a> to request access.</div>
          {false && <a className='button' href="mailto:support@telezing.com" target="_self">Request Access</a>}
        </div>
      </div>
      <div className='footer'>
          <div className='copyright'>&copy; FabZing Pty Ltd 2024</div>
      </div>
    </div>
    <a className='sign_in' href="https://telezing.com/web/106" target="_self">SIGN IN</a>
    </div>
  );
}

export default App;
